
<template>
  <div class="row justify-content-center">
    <div class="col-lg-10">
      <div class="card">
        <div class="card-body">
          <div class="row gx-3 gy-3">
              <div class="col-md-6">
                <label class="form-label" for="firstName">First name</label>
                <input id="firstName" type="text" class="form-control" v-model="data.first_name"
                :class="{ 'is-invalid': v$.data.first_name.$error }" placeholder="First name" name="first_name" />
                <div v-if="v$.data.first_name.$error"
                  class="invalid-feedback">
                  <span v-if="v$.data.first_name.required.$message">
                    {{ v$.data.first_name.required.$message }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <label class="form-label" for="LastName">Last name</label>
                <input id="LastName" type="text" class="form-control" v-model="data.last_name"
                :class="{ 'is-invalid': v$.data.last_name.$error }"  placeholder="Last Name" name="last_name" />
                <div v-if="v$.data.last_name.$error"
                  class="invalid-feedback">
                  <span v-if="v$.data.last_name.required.$message">
                    {{ v$.data.last_name.required.$message }}</span>
                </div>
              </div>
              <div class="col-md-6">
                  <label class="form-label" for="inputEmail">Email Address</label>
                  <input id="inputEmail" label="Email" v-model="data.email"
                  :class="{ 'is-invalid': v$.data.email.$error }" type="email" placeholder="Enter email" name="email" class="form-control"/>
                  <div v-for="(item, index) in v$.data.email.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>  
              </div>
              <div class="col-md-6">
                <label class="form-label" for="inputMobile">Mobile</label>
                <input id="inputMobile" autocomplete="false" type="text" class="form-control" v-model="data.mobile"
                  :class="{ 'is-invalid': v$.data.mobile.$error  }" placeholder="mobile" name="mobile" />
                  <div v-if="v$.data.mobile.$error"
                    class="invalid-feedback">
                    <span v-if="v$.data.mobile.required.$message">
                      {{ v$.data.mobile.required.$message }}</span>
                  </div>
              </div>
              <div class="col-md-6">
                  <label class="form-label">Role</label>
                  <select class="form-select" v-model="data.role" :class="{ 'is-invalid': v$.data.role.$error  }"
                     name="input-role" placeholder="--select role--">
                      <option disabled value="" key="-1">-- select role--</option>
                      <option :value="role.name" v-for="(role, index) in roleOptions" 
                      :key="index">{{$filters.capitalize(role.name)}}</option>
                  </select>
                  <div v-for="(item, index) in v$.data.role.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
              </div>
              <div class="col-md-6">
                <label class="form-label" for="password">Password</label>
                 <input type="password" v-model="data.password" class="form-control"
                  name="password" id="password" placeholder="Enter password" :class="{ 'is-invalid': v$.data.password.$error }">
                  <div v-for="(item, index) in v$.data.password.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                  </div>
              </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <label class="form-label d-block">Gender</label>
              <div :class="{ 'is-invalid': v$.data.gender.$error }">
                <div v-for="(gender, gIndex) in genderOptions" :key="gIndex" class="form-check form-check-inline">
                  <input v-model="data.gender" :id="`inlineGenderRadio${gIndex}`" 
                  class="form-check-input" type="radio" name="inlineRadioOptions" :value="gender.value">
                  <label class="form-check-label" :for="`inlineGenderRadio${gIndex}`">{{gender.text}}</label>
                </div>
              </div>
              <div v-for="(item, index) in v$.data.gender.$errors" :key="index"
                class="invalid-feedback">
                <span v-if="item.$message">{{ item.$message }}</span>
              </div>
            </div>
          </div>
          <div class="row mt-3 justify-content-end">
            <div class="col-md-6">
              <div class="text-end">
                <button type="button" @click.prevent="createUser" class="btn btn-primary mt-2">
                    <i class="mdi mdi-content-save"></i> Create
                </button>
              </div>
            </div>
          </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { required, email, minLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      genderOptions: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
        { text: 'Others', value: 'others' }
      ],
      statusOptions: [
        {label: "Active", value:'active'},
        {label: "Deactivated", value:'deactivated'},
        {label: "Blocked", value:'blocked'}
      ],
      data: {
        first_name: "",
        last_name: "",
        email: "",
        gender: "",
        mobile: null,
        role: "",
        password: "",
      },
    }
  },
  validations: {
    data:{
      first_name: {
        required: helpers.withMessage("First name is required", required),
      },
      last_name: {
        required: helpers.withMessage("Last name is required", required),
      },
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      mobile: {
        required: helpers.withMessage("Phone number is required", required),
      },
      gender: {
        required: helpers.withMessage("Select gender", required),
      },
      role: {
        required: helpers.withMessage("User role is required", required),
      },
      password: {
        required: helpers.withMessage("Password is required", required),
        minLength: helpers.withMessage("minimum of 6 characters", minLength(6))
      },
    }
  },
  computed: {
    roleOptions() {
      return this.$store.state.userList.roles.map(role => {
        return {
          name: role.name,
          label: role.name
      }})
    },
  },
  methods: {
    createUser() {
       this.v$.$validate().then(result =>{
        if (!result) { return;}
        this.$store.dispatch("changeLoaderValue", true)
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("userList/createUser", formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.resetForm()
          }
         })
      })
    },
    resetForm() {
      this.data = {
        first_name: "",
        last_name: "",
        email: "",
        mobile: null,
        role: "",
        gender: "",
        password: "",
      }
      this.v$.$reset()
    },
  },
  created(){
    if(!this.$store.state.userList.roles.length){
      this.$store.dispatch("userList/fetchRoles")
    }
  },
}
</script>
